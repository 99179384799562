<template>
  <div class="levelOneDiv1" style="background-color: #fff;">
    <div class="small_title">
      <templateTitle :title="title" :isBack="true" @handleBack="btnClose"></templateTitle>

      <el-button v-if="type == 'view'" size="mini" type="primary" plain icon="el-icon-edit" @click="editCols"
        >编辑</el-button
      >
    </div>

    <div v-if="type == 'add'">
      <el-form
        :inline="true"
        :model="searchObj"
        :rules="rules"
        ref="form"
        style="align-items: center;margin-bottom: 4px;"
        class="user-search flex-between"
        @submit.native.prevent
        label-position="top"
      >
        <div>
          <el-form-item class="budgetYear" label="新增年度" prop="year">
            <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div v-if="type == 'add'" class="small_title">
      <templateDivider title="样本调查及检查资料情况"></templateDivider>
      <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="addCols">增加</el-button>
    </div>

    <div v-if="false">
      <el-table
        v-loading="loading"
        ref="table"
        :data="listData"
        style="width: calc(100% - 0.125rem);overflow-x: auto;"
        height="400px"
        :header-cell-style="{ backgroundColor: '#F5F7FA' }"
      >
        <el-table-column fixed type="index" label="序号" width="60" align="center">
          <template slot-scope="scope">
            <span v-if="scope.$index != 0">{{ scope.$index }}</span>
          </template>
        </el-table-column>

        <el-table-column fixed prop="countyId" align="center" min-width="140" label="行政区域">
          <template slot-scope="scope">
            <div v-if="scope.$index == 0">{{ scope.row.county }}</div>
            <div v-else>
              <el-cascader  :append-to-body="false"  
                v-model="scope.row.countyId"
                size="mini"
                :disabled="type == 'view' || type == 'charts'"
                :options="levalRegion"
                :props="treeProps"
                @change="handleChange($event, scope.$index)"
                :show-all-levels="false"
                placeholder="请选择"
              >
              </el-cascader>
            </div>
          </template>
        </el-table-column>

        <el-table-column align="center" min-width="140" prop="sampleVillagesCount" label="调查样本村（个）">
          <template slot-scope="scope">
            <div v-if="scope.$index == 0">{{ calculateTheTotal('sampleVillagesCount') }}</div>
            <el-input
              v-else
              size="mini"
              :disabled="type == 'view' || type == 'charts'"
              type="number"
              min="0"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model="scope.row.sampleVillagesCount"
              placeholder="请输入"
            >
            </el-input>
          </template>
        </el-table-column>

        <el-table-column align="center" min-width="120" prop="householdCount" label="调查户数（户）">
          <template slot-scope="scope">
            <div v-if="scope.$index == 0">{{ calculateTheTotal('householdCount') }}</div>
            <el-input
              v-else
              size="mini"
              :disabled="type == 'view' || type == 'charts'"
              type="number"
              min="0"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model="scope.row.householdCount"
              placeholder="请输入"
            >
            </el-input>
          </template>
        </el-table-column>

        <el-table-column align="center" min-width="150" prop="typicalProjectsCount" label="抽查典型项目（个）">
          <template slot-scope="scope">
            <div v-if="scope.$index == 0">{{ calculateTheTotal('typicalProjectsCount') }}</div>
            <el-input
              v-else
              size="mini"
              :disabled="type == 'view' || type == 'charts'"
              type="number"
              min="0"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model="scope.row.typicalProjectsCount"
              placeholder="请输入"
            >
            </el-input>
          </template>
        </el-table-column>

        <el-table-column align="center" min-width="150" prop="forumMeetingsCount" label="召开座谈会（次）">
          <template slot-scope="scope">
            <div v-if="scope.$index == 0">{{ calculateTheTotal('forumMeetingsCount') }}</div>
            <el-input
              v-else
              size="mini"
              :disabled="type == 'view' || type == 'charts'"
              type="number"
              min="0"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model="scope.row.forumMeetingsCount"
              placeholder="请输入"
            >
            </el-input>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          min-width="180"
          prop="participantsResidentsCount"
          label="参与座谈移民群众（人）"
        >
          <template slot-scope="scope">
            <div v-if="scope.$index == 0">{{ calculateTheTotal('participantsResidentsCount') }}</div>
            <el-input
              v-else
              size="mini"
              :disabled="type == 'view' || type == 'charts'"
              type="number"
              min="0"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model="scope.row.participantsResidentsCount"
              placeholder="请输入"
            >
            </el-input>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          min-width="180"
          prop="participantsOfficialsCount"
          label="参与座谈移民干部（人）"
        >
          <template slot-scope="scope">
            <div v-if="scope.$index == 0">{{ calculateTheTotal('participantsOfficialsCount') }}</div>
            <el-input
              v-else
              size="mini"
              :disabled="type == 'view' || type == 'charts'"
              type="number"
              min="0"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model="scope.row.participantsOfficialsCount"
              placeholder="请输入"
            >
            </el-input>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          min-width="220"
          prop="reviewAssistancePlansCount"
          label="查阅移民后扶相关规划方案（份）"
        >
          <template slot-scope="scope">
            <div v-if="scope.$index == 0">{{ calculateTheTotal('reviewAssistancePlansCount') }}</div>
            <el-input
              v-else
              size="mini"
              :disabled="type == 'view' || type == 'charts'"
              type="number"
              min="0"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model="scope.row.reviewAssistancePlansCount"
              placeholder="请输入"
            >
            </el-input>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          min-width="190"
          prop="reviewAssistancePopulationDataCount"
          label="查阅后扶人口相关资料（份）"
        >
          <template slot-scope="scope">
            <div v-if="scope.$index == 0">{{ calculateTheTotal('reviewAssistancePopulationDataCount') }}</div>
            <el-input
              v-else
              size="mini"
              :disabled="type == 'view' || type == 'charts'"
              type="number"
              min="0"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model="scope.row.reviewAssistancePopulationDataCount"
              placeholder="请输入"
            >
            </el-input>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          min-width="260"
          prop="reviewAssistanceProjectFundsDataCount"
          label="查阅移民后扶项目和资金相关资料（份）"
        >
          <template slot-scope="scope">
            <div v-if="scope.$index == 0">{{ calculateTheTotal('reviewAssistanceProjectFundsDataCount') }}</div>
            <el-input
              v-else
              size="mini"
              :disabled="type == 'view' || type == 'charts'"
              type="number"
              min="0"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model="scope.row.reviewAssistanceProjectFundsDataCount"
              placeholder="请输入"
            >
            </el-input>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          min-width="240"
          prop="reviewWorkSummariesCount"
          label="查阅工作总结等其他相关资料（份）"
        >
          <template slot-scope="scope">
            <div v-if="scope.$index == 0">{{ calculateTheTotal('reviewWorkSummariesCount') }}</div>
            <el-input
              v-else
              size="mini"
              :disabled="type == 'view' || type == 'charts'"
              type="number"
              min="0"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model="scope.row.reviewWorkSummariesCount"
              placeholder="请输入"
            >
            </el-input>
          </template>
        </el-table-column>

        <el-table-column fixed="right" align="center" prop="name" min-width="80" label="操作" v-if="type == 'add'">
          <template slot-scope="scope">
            <div v-if="scope.$index == 0"></div>
            <el-link v-else type="danger" @click="removeCol(scope.$index)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>

      <!-- <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination> -->
    </div>

    <el-row>
      <!-- 纵向表头设计 -->
      <el-col :span="5" style="background-color: #F9FAFE;">
        <el-row v-if="type == 'add' || type == 'edit'">
          <div class="leftTitletStyle">操作</div>
        </el-row>
        <el-row>
          <div class="leftTitletStyle">行政区域</div>
        </el-row>

        <el-row>
          <div class="leftTitletStyle">调查样本村（个）</div>
        </el-row>

        <el-row>
          <div class="leftTitletStyle">调查户数（个）</div>
        </el-row>

        <el-row>
          <div class="leftTitletStyle">抽查典型项目（个）</div>
        </el-row>

        <el-row>
          <div class="leftTitletStyle">召开座谈会（次）</div>
        </el-row>

        <el-row>
          <div class="leftTitletStyle">参与座谈移民群众（人）</div>
        </el-row>
        <el-row>
          <div class="leftTitletStyle">参与座谈移民干部（人）</div>
        </el-row>

        <el-row>
          <div class="leftTitletStyle">查阅移民后扶相关规划方案（份）</div>
        </el-row>

        <el-row>
          <div class="leftTitletStyle">查询后扶人口相关资料（份）</div>
        </el-row>

        <el-row>
          <div class="leftTitletStyle">查阅移民后扶项目和资金相关资料（份）</div>
        </el-row>

        <el-row>
          <div class="leftTitletStyle">查阅工作总结等其他相关资料（份）</div>
        </el-row>
      </el-col>

      <el-col :span="2" style="background-color: #F9FAFE;">
        <el-row>
          <div class="leftTotalStyle">合计</div>
        </el-row>

        <el-row v-if="type == 'add' || type == 'edit'">
          <div class="leftTotalStyle"></div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('sampleVillagesCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('householdCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('typicalProjectsCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('forumMeetingsCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('participantsResidentsCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('participantsOfficialsCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('reviewAssistancePlansCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('reviewAssistancePopulationDataCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('reviewAssistanceProjectFundsDataCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('reviewWorkSummariesCount') }}</div>
            </div>
          </el-col>
        </el-row>
      </el-col>

      <!-- 2.纵向数据展示 -->
      <el-col :span="17">
        <div class="main_box">
          <!-- -纵列数据遍历 -->
          <div :style="boxWidth" class="clearFloat">
            <div>
              <el-row class="flex">
                <el-row style="width: 150px;" v-for="(col, index) in listData" :key="col.id">
                  <el-col :span="24">
                    <el-row v-if="type == 'add'">
                      <div class="leftTitletStyle">
                        <el-link type="danger" @click="removeCol(index)">删除</el-link>
                      </div>
                    </el-row>

                    <el-row v-if="type == 'edit'">
                      <div class="leftTitletStyle"></div>
                    </el-row>

                    <el-col>
                      <template v-for="key1 in Object.keys(col)">
                        <el-row v-if="key1 == 'countyId'">
                          <div class="contentStyle">
                            <el-cascader  :append-to-body="false"  
                              v-if="type == 'add' || type == 'edit'"
                              v-model="col[key1]"
                              size="mini"
                              :options="levalRegion"
                              :props="treeProps"
                              @change="handleChange($event, index)"
                              :show-all-levels="false"
                              placeholder="请选择"
                            >
                            </el-cascader>
                            <div class="box_view" v-else>
                              {{ col['county'] }}
                            </div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'sampleVillagesCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'householdCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'typicalProjectsCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'forumMeetingsCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'participantsResidentsCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'participantsOfficialsCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'reviewAssistancePlansCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'reviewAssistancePopulationDataCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'reviewAssistanceProjectFundsDataCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'reviewWorkSummariesCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>
                      </template>
                    </el-col>
                  </el-col>
                </el-row>
              </el-row>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="import_opration" v-if="type == 'add' || type == 'edit'">
      <el-button size="small" plain @click="btnClose()">取消</el-button>
      <el-button type="primary" size="small" @click="submitForm()">提交</el-button>
    </div>
  </div>
</template>

<script>
import templateTitle from '@/components/templateView/templateTitle.vue'
import templateDivider from '@/components/templateView/templateDivider.vue'
import budgetYear from '@/components/templateView/budgetYear.vue'
import { _getLevelRegion } from '@/api/keepHome'

import {
  _InvestmentAdd,
  _InvestmentInfo,
  _InvestmentEdit
} from '@/api/modular/postImmigrationService/workingCondition/verificationOfData.js'

export default {
  data() {
    return {
      type: '',
      row: {},
      title: '',
      input: '',
      listData: [],
      searchObj: {
        year: ''
      },
      rules: {
        year: [{ required: true, message: '请选择年度', trigger: 'blur' }]
      },
      loading: false,
      options: [],
      treeProps: { label: 'name', value: 'id', children: 'childRegions', checkStrictly: true },
      levalRegion: []
    }
  },
  // 注册组件
  components: {
    templateTitle,
    templateDivider,
    budgetYear
  },
  computed: {
    //
    boxWidth() {
      let num = 150 * this.listData.length
      return `width:${num}px`
    }
  },
  created() {
    this.getLevelRegion()
  },
  mounted() {},

  methods: {
    handleChange(value, index) {
      let obj = this.getPids(value)
      this.listData[index].countyId = obj.id
      this.listData[index].county = obj.name
      this.listData[index].countyPids = obj.pids
      this.listData[index].orderNo = obj.orderNo
    },
    // 获取选中节点的pids值
    getPids(selectedOptions) {
      const selectedNode = this.findNodeById(this.levalRegion, selectedOptions[selectedOptions.length - 1])
      return selectedNode ? selectedNode : []
    },

    // 在数据源中查找指定ID的节点
    findNodeById(nodes, id) {
      for (const node in nodes) {
        if (nodes[node].id === id) {
          return nodes[node]
        }
        if (nodes[node].childRegions && nodes[node].childRegions.length > 0) {
          const foundNode = this.findNodeById(nodes[node].childRegions, id)
          if (foundNode) {
            return foundNode
          }
        }
      }
      return null
    },
    getLevelRegion() {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.levalRegion = this.formatTreeData(res.data)
        }
      })
    },
    formatTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].childRegions.length < 1) {
          data[i].childRegions = undefined
        } else {
          this.formatTreeData(data[i].childRegions)
        }
      }
      return data
    },
    calculateTheTotal(name) {
      let sum = 0
      for (let i = 0; i < this.listData.length; i++) {
        let ele = this.listData[i]
        sum += Number(ele[name])
      }
      return sum
    },
    refreshYear(year) {
      this.searchObj.year = year
    },
    add(type, row) {
      this.type = type
      this.row = row
      // let obj = {
      //   year: this.searchObj.year, //年份
      //   countyId: null, //县（市区）
      //   county: '总计', //县（市区）
      //   orderNo: null,
      //   sampleVillagesCount: null,
      //   householdCount: null,
      //   typicalProjectsCount: null,
      //   forumMeetingsCount: null,
      //   participantsResidentsCount: null,
      //   participantsOfficialsCount: null,
      //   reviewAssistancePlansCount: null,
      //   reviewAssistancePopulationDataCount: null,
      //   reviewAssistanceProjectFundsDataCount: null,
      //   reviewWorkSummariesCount: null,
      //   countyPids: '' //pids
      // }
      // this.listData.unshift(obj)
      // this.getBasicInfo()
      this.title = '新增'
    },
    view(type, row) {
      this.type = type
      this.row = row

      this.title = type == 'view' ? '详情' : '查看统计表'
      this.getBasicInfo()
    },
    editCols() {
      this.type = 'edit'
      this.title = '编辑'
      this.$nextTick(() => {
        this.$refs.table.doLayout()
      })
    },
    btnClose() {
      this.$emit('handleBack')
    },
    addCols() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          let obj = {
            year: this.searchObj.year, //年份
            countyId: null, //县（市区）
            county: '', //县（市区）
            orderNo: null,
            sampleVillagesCount: null,
            householdCount: null,
            typicalProjectsCount: null,
            forumMeetingsCount: null,
            participantsResidentsCount: null,
            participantsOfficialsCount: null,
            reviewAssistancePlansCount: null,
            reviewAssistancePopulationDataCount: null,
            reviewAssistanceProjectFundsDataCount: null,
            reviewWorkSummariesCount: null,
            countyPids: '' //pids
          }

          // if (this.listData.length) {
          //   let flag = this.listData.every(ele => ele.countyId)
          //   if (flag) {
          //     this.listData.splice(this.listData.length, 0, obj)
          //   } else {
          //     this.$message.error('请选择县（市区）')
          //   }
          // } else {
          //   this.listData.splice(this.listData.length, 0, obj)
          // }
          this.listData.splice(this.listData.length, 0, obj)
        }
      })
    },
    removeCol(index) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.listData.splice(index, 1)
          this.$message.success('删除成功')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    refreshTable() {
      this.getBasicInfo()
    },
    getBasicInfo() {
      let params = {
        id: this.row.id
      }
      _InvestmentInfo(params).then(res => {
        if (res.code == 200) {
          this.listData = [res.data]
          // let obj = {
          //   year: this.searchObj.year, //年份
          //   countyId: null, //县（市区）
          //   county: '总计', //县（市区）
          //   orderNo: null,
          //   sampleVillagesCount: null,
          //   householdCount: null,
          //   typicalProjectsCount: null,
          //   forumMeetingsCount: null,
          //   participantsResidentsCount: null,
          //   participantsOfficialsCount: null,
          //   reviewAssistancePlansCount: null,
          //   reviewAssistancePopulationDataCount: null,
          //   reviewAssistanceProjectFundsDataCount: null,
          //   reviewWorkSummariesCount: null,
          //   countyPids: '' //pids
          // }
          // this.listData.unshift(obj)
          console.log(this.listData, '========获取详情==========')
        }
      })
    },

    submitForm() {
      let flag = this.listData.every(ele => ele.countyId)

      switch (this.type) {
        case 'add':
          if (flag) {
            _InvestmentAdd(this.listData).then(res => {
              if (res.code == 200) {
                this.$message.success('添加成功')
                this.btnClose()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }

          break
        case 'edit':
          if (flag) {
            _InvestmentEdit(this.listData[0]).then(res => {
              if (res.code == 200) {
                this.type = 'view'
                this.$message.success('修改成功')
                this.getBasicInfo()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }

          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
@import '@/common/scss/formview.scss';
</style>
